import { useFormikContext } from 'formik';
import React from 'react';

import { DaytimeDatesSelector } from './DaytimeDatesSelector';
import { OvernightDatesSelector } from './OvernightDatesSelector';
import { RwbDatesSelector } from './RwbDatesSelector';
import { isOvernightServiceSelected, isRwbServiceSelected } from '../../utils';

import type { DateFormat } from '@madpaws/design-system';
import type { ReactElement } from 'react';
import type { SearchFilters } from '~/common/types/search';

type DateSelectorProps = {
  dateFormat?: DateFormat;
  disabledDates?: string[];
  isCalendarIconHidden?: boolean;
  isCheckboxLegendVisuallyHidden?: boolean;
  isLabelVisuallyHidden?: boolean;
  name?: string;
  nameFrom?: string;
  nameTo?: string;
  placeholder?: string;
  rwbStartingFromPlaceholder?: string;
  weekDaySelectorLabel?: string;
};

export const DateSelector = ({
  disabledDates = [],
  isCalendarIconHidden = true,
  isCheckboxLegendVisuallyHidden,
  isLabelVisuallyHidden,
  name,
  nameFrom,
  nameTo,
  dateFormat = 'short',
  placeholder,
  rwbStartingFromPlaceholder,
  weekDaySelectorLabel,
}: DateSelectorProps): ReactElement => {
  const {
    values: { service, bookingType },
  } = useFormikContext<SearchFilters>();

  if (isOvernightServiceSelected(service.type)) {
    return (
      <div>
        <OvernightDatesSelector
          dateFormat={dateFormat}
          disabledDates={disabledDates}
          isCalendarIconHidden={isCalendarIconHidden}
          isLabelVisuallyHidden={isLabelVisuallyHidden}
          nameFrom={nameFrom}
          nameTo={nameTo}
        />
      </div>
    );
  }

  if (isRwbServiceSelected(service.type, bookingType)) {
    return (
      <div>
        <RwbDatesSelector
          dateFormat={dateFormat === 'fancy' ? 'fancyWeek' : dateFormat}
          disabledDates={disabledDates}
          isCalendarIconHidden={isCalendarIconHidden}
          isCheckboxLegendVisuallyHidden={isCheckboxLegendVisuallyHidden}
          isLabelVisuallyHidden={isLabelVisuallyHidden}
          name={name}
          rwbStartingFromPlaceholder={rwbStartingFromPlaceholder}
          rwbplaceholder={placeholder}
          weekDaySelectorLabel={weekDaySelectorLabel}
        />
      </div>
    );
  }

  return (
    <div>
      <DaytimeDatesSelector
        dateFormat={dateFormat}
        disabledDates={disabledDates}
        isCalendarIconHidden={isCalendarIconHidden}
        isLabelVisuallyHidden={isLabelVisuallyHidden}
        name={name}
        placeholder={placeholder}
      />
    </div>
  );
};
