import { DatePickerMulti } from '@madpaws/design-system';
import { useFormikContext } from 'formik';
import React from 'react';

import { trackEvent } from '~/components/analytics/analytics';
import { UPDATE_FILTER_ONE_OFF_CALENDAR_EVENT_NAME } from '~/components/analytics/constants';

import { SEARCH_FILTERS_FORM_KEYS as KEYS } from '../../constants';

import type { DateFormat } from '@madpaws/design-system';
import type { ReactElement } from 'react';
import type { SearchFilters } from '~/common/types/search';

type DaytimeDatesSelectorProps = {
  dateFormat?: DateFormat;
  disabledDates?: string[];
  isCalendarIconHidden?: boolean;
  isLabelVisuallyHidden?: boolean;
  name?: string;
  placeholder?: string;
};

export const DaytimeDatesSelector = ({
  dateFormat = 'short',
  disabledDates = [],
  isCalendarIconHidden = true,
  isLabelVisuallyHidden,
  name,
  placeholder = 'Add days',
}: DaytimeDatesSelectorProps): ReactElement => {
  const {
    values: {
      chronology: { scheduledDates },
    },
    setFieldValue,
  } = useFormikContext<SearchFilters>();

  const formattedScheduledDates = scheduledDates ?? [];

  const onDateSelection = (dates: string[]): void => {
    setFieldValue(KEYS.scheduledDates, dates);
    trackEvent(UPDATE_FILTER_ONE_OFF_CALENDAR_EVENT_NAME);
  };

  return (
    <DatePickerMulti
      dateFormat={dateFormat}
      disabledDates={disabledDates}
      hasTighterSpacing
      isCalendarIconHidden={isCalendarIconHidden}
      isLabelVisuallyHidden={isLabelVisuallyHidden}
      label="Dates"
      name={name ? name : KEYS.scheduledDates}
      onDateSelection={onDateSelection}
      placeholder={placeholder}
      value={formattedScheduledDates}
    />
  );
};
